import * as React from "react"
import Seo from "../components/seo";
import EventLayout from "../components/event-layout";
import {
    CalendarIcon,
    CheckCircleIcon, CheckIcon,
    LocationMarkerIcon,
    MailIcon,
    UploadIcon
} from "@heroicons/react/outline";
import {
    getAccessToken,
    getEvitatAuthPopupWidow,
    getIsAuthenticatedCookie, getUser,
    isAuthenticated, logout
} from "../services/auth";
import Cookies from "universal-cookie/es6";
import { navigate } from "gatsby"
import {
    authClientId,
    backAuthServerUrl,
    eventPageClientUrl,
    evitatCommunityId,
    frontClientUrl,
    frontPopupClientUrl,
    removeAuthPopupWindowCookie,
    authCallbackUrl
} from "../services/constant";
import Moment from 'moment';
import momentTimezone from 'moment-timezone'
// import { PopupButton } from '@typeform/embed-react';

const cookies = new Cookies();

// change the event id if needed. You can get the event id from community
const eventId = 3228364; //Wood fibre insulation for better and healthier buildings

export const isBrowser = () => typeof window !== "undefined"
// const callbackOriginUrl = isBrowser() && window.location.origin + "/event";


const panelists = [
    {
        name: 'James Cornell',
        designation: 'Guest',
        company: 'Life Panels Pty Ltd'
    },
    {
        name: 'Lib Elphick',
        designation: 'Guest',
        company: 'Renovator'
    },
    {
        name: 'Simone Schenkel',
        designation: 'Host',
        company: 'GruenEcoDesign '
    },
    {
        name: 'Sonja Markovic',
        designation: 'Co-Host',
        company: 'Evitat'
    },
]

function sendRESTRSVP(method, event_id = null, member_email = null) {
    if (method == 'postRSVP') {
        const obj = {
            communinty_id: evitatCommunityId,
            event_id,
            member_email
        }
        return fetch(`/.netlify/functions/circle-event-RSVP`, {
            method: 'post',
            body: JSON.stringify(obj),
            headers: {'Content-Type': 'application/json'}
        })
            .then(result => result.json())
            .then(result => {
                if (result.message === "RSVP'd to the event.") {
                    return true;
                }
            })
            .catch((err) => console.log('err', err));
    } else if (method == 'delRSVP') {
        const obj = {
            communinty_id: evitatCommunityId,
            event_id,
            member_email
        }

        return fetch(`/.netlify/functions/circle-event-RSVP`, {
            method: 'delete',
            body: JSON.stringify(obj),
            headers: {'Content-Type': 'application/json'}
        })
            .then(result => result.json())
            .then(result => {
                if (result.message === "Un-RSVP successful from the event") {
                    return true;
                }
            })
            .catch((err) => console.log('err', err));

    }
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// function that extracts text from HTML tags
function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
};

class LoadingTextAnimation extends React.Component {
    render() {
        return (
            <div className="animate-pulse">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">Loading...</span>
                </h1>
            </div>
        );
    }
}

class ButtonLoadingAnimation extends React.Component {
    render() {
        return (
            <div className="relative inline-block text-left">
                <button
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-2xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    disabled>
                    <svg className="animate-spin -ml-1 mr-3 h-8 w-8 text-black" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Loading...
                </button>
            </div>
        );
    }
}


class EventPage extends React.Component {
    constructor(props) {
        super(props);

        // Check Evitat Auth
        const timeout = setTimeout(() => {
            const isAuthCookie = getIsAuthenticatedCookie();
            if (isAuthCookie != "undefined" && (isAuthCookie == "1" || isAuthCookie == 1)) {
                if (!getAccessToken()) {
                    this.authExternalLink(this);
                    // window.location.href = this.state.loginUrl;
                    clearTimeout(timeout);
                }
            }
        }, 3000);

    }
    eventInfo
    // state var
    state = {
        eventTitle: '',
        toggle: false,
        getUser: null,
        eventButtonLoading: true,
        eventLoading: true,
        error: false,
        logoutUrl: backAuthServerUrl() + "/oauth/sso-logout?callback_uri=" + frontClientUrl(),
        loginUrl: backAuthServerUrl() + "/oauth/authorize?response_type=code&client_id=" + authClientId() + "&redirect_uri=" + authCallbackUrl() + "&scope=read",
        popupLoginUrl: backAuthServerUrl() + "/oauth/authorize?response_type=code&client_id=" + authClientId() + "&redirect_uri=" + frontPopupClientUrl() + "&scope=read",
        eventPageLoginUrl: backAuthServerUrl() + "/oauth/authorize?response_type=code&client_id=" + authClientId() + "&redirect_uri=" + eventPageClientUrl() + "&scope=read",
        eventButtonConfig: { action: "loginUser", text: "Log in or Sign up to RSVP", clicked: false },
        logInButtonConfig: {action: "logIn", text: "Log in"},
        isDisabled: false
    };

    // function that runs when the page is being loaded
    componentDidMount(){

        // if(this.eventInfo){
        //     return;
        // }
        // set event info when the page is loaded
        this.eventInfo = this.getEventInfo();

        this.setState({eventLoading: true});
        this.eventInfo.then(eventInfoRes => {
            if(!!eventInfoRes){
                this.disableRSVPButton(eventInfoRes.starts_at, eventInfoRes.rsvp_disabled);
                this.setState({eventTitle: eventInfoRes.name, 
                    eventStartAt: eventInfoRes.starts_at, 
                    eventEndAt: eventInfoRes.ends_at, 
                    eventTimeZone: momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr(),
                    eventInfo: eventInfoRes
                });
                const eventDescriptionWithHTML = eventInfoRes.body.body;
                const eventDescription = extractContent(eventDescriptionWithHTML);

                // remove enquire button text and set the description in the description area
                this.setState({eventDescription: eventDescription.replace('Enquire','')});
                this.setState({eventLoading: false});
            }
        }).catch((e) => {
            console.log(e);
        });

        if(!getAccessToken()){
            this.setState({eventButtonLoading: false});
            isAuthenticated(true);
        }

        const isBrowser = () => typeof window !== "undefined";
        const interval = setInterval(() => {
            const authPopupOpen = getEvitatAuthPopupWidow();
            if(getAccessToken()){
                this.setState({eventLoading: true});
                this.eventInfo.then(eventInfoRes => {
                    if(!!eventInfoRes){
                        this.disableRSVPButton(eventInfoRes.starts_at, eventInfoRes.rsvp_disabled);
                        this.setState({eventLoading: false});
                    }
                });
                this.setState({logInButtonConfig : {action: "logOut", text: "Log out"}});
                this.state.getUser = getUser()
                // this.setState({ getUser:  getUser()});
                const interval1 = setInterval(() => {
                    this.handleInitRSVP();
                    clearInterval(interval1);
                }, 1000);
                if(authPopupOpen){
                    removeAuthPopupWindowCookie();
                    setTimeout( () => {
                        this.state.windowReference.close();
                        // Add Custom Navigate URL
                        navigate('/event');
                    },4000);
                }
                clearInterval(interval);
            }
        },2000);
    }

    disableRSVPButton = (starts_at, rsvp_disabled) => {
        const eventStartDate = Date.parse(starts_at,"YYYY-MM-DDTHH:mm:ss.sssZ");
        const currentDT = Date.now();
        if((rsvp_disabled ||  currentDT > eventStartDate) && getAccessToken()){
            this.setState({ isDisabled: true });
            this.setState({ hoverMessage: "You cannot respond to this event anymore" });
        }
    }
    // function to update the check icon
    checkIconHandler = () => {
        if(this.state.eventButtonConfig.action === "undoRSVP")
            return <CheckCircleIcon className="-ml-0.5 mr-3 h-8 w-8 text-white" aria-hidden="true" />;

        return null;
    }

    getUserRSVPInfo = () => {
        return fetch(`/.netlify/functions/circle-event-RSVP?communinty_id=` + evitatCommunityId + `&event_id=` + eventId + `&member_email=` + this.state.getUser.email,{
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        })
            .then(result => result.json())
            .then(result => {
                return result;
            })
            .catch((err) => console.log('err', err));
    }

    // function that returns event info against a event id
    getEventInfo = () => {
        let eventInfo = {};
        const eventsInfo = fetch(`/.netlify/functions/circle-event-info?communinty_id=` + evitatCommunityId,{
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        })
            .then(result => result.json())
            .then(result => {
                return result;
            })
            .catch((err) => console.log('err', err));

        return eventsInfo.then(response => {
            if(response != undefined){
                eventInfo = Object.values(response).filter(value => {
                    if(value.id === eventId){
                        return value;
                    }
                })[0]
                return eventInfo
            }
        })
    }

    // function to handle the initial updating of buttons
    handleInitRSVP = () => {
        this.setState({eventButtonLoading: true});
        if(this.state.eventButtonConfig.clicked){
            this.registerUserToEvent();
        }
        else{
            this.isUserGoingToEvent().then(isUserGoing => {
                isUserGoing == true ? this.setState({eventButtonConfig : {action: "undoRSVP", text: "You are going!"}}) : this.setState({eventButtonConfig : {action: "doRSVP", text: "RSVP"}});
                this.setState({eventButtonLoading: false});
            });
        }

    }

    // funciton to check if the user is going to the event (called when the cookie does not exist)
    isUserGoingToEvent = () => {
        return this.getUserRSVPInfo().then( response => {
            if(!!response){
                return !!response.member_email;
            }

            return false;
        });
    }

    // function to open external login popup window
    authExternalLink = (e) => {
      if (e && e.target && e.target.id && e.target.id === "RSVP") {
        this.setState({
          eventButtonConfig: {
            action: "loginUser",
                text: "Log in or Sign up to RSVP",
            clicked: true,
          },
        })
      }
      window.localStorage.setItem("stateRedirectURL", window.location.href);
      window.location.replace(this.state.loginUrl);
    }

    registerUserToEvent = () => {
        const user = JSON.parse(window.localStorage.getItem("userObj"));
        sendRESTRSVP('postRSVP', eventId, user.email).then(response => {
            if(response == true){
                this.setState({eventButtonConfig : {action: "undoRSVP", text: "You are going!", clicked: false}});
                const mcFormFields = {
                    name: user.name,
                    email: user.email,
                    eventname: this.state.eventTitle,
                    eventInfo: this.state.eventInfo,
                    type: 'event'
                };
                this.triggerEmail(mcFormFields).then(res => {
                    console.log('email sent', res);
                });
            }
            else{
                this.setState({eventButtonConfig : {action: "doRSVP", text: "RSVP", clicked: false}});
            }
            this.setState({eventButtonLoading: false});
        })
    }

    triggerEmail = (mcForm) => {
        return fetch('/.netlify/functions/trigger-email', {
            method: 'POST',
            body: JSON.stringify({mcForm}),
            headers: {'Content-Type': 'application/json'}
        });
    }

    // function to handle button click event
    handleRSVP = (e) => {
        this.setState({loading: true});
        if(this.state.eventButtonConfig.action  ==  'doRSVP'){
            this.registerUserToEvent();
        }
        else if(this.state.eventButtonConfig.action  ==  'undoRSVP'){
            sendRESTRSVP('delRSVP', eventId, this.state.getUser.email).then(response => { if(response == true){
                this.setState({eventButtonConfig : {action: "doRSVP", text: "RSVP"}});
                this.setState({eventButtonLoading: false});
            }})

        }
        else if(this.state.eventButtonConfig.action  ==  'loginUser'){
            this.authExternalLink(e)
        }
    };

    // handleLoginUpdate = (e) => {
    //     if(this.state.logInButtonConfig.action == "logIn"){
    //         this.authExternalLink(e);
    //     }
    //     else if(this.state.logInButtonConfig.action == "logOut"){
    //         this.setState({logInButtonConfig : {action: "logIn", text: "Log In"}});
    //         logout();
    //         window.location.href = backAuthServerUrl() + "/oauth/sso-logout?callback_uri=" + callbackOriginUrl;
    //     }
    // }

    render() {
        return (
            <EventLayout>
                <Seo title="Event"/>
                <div className="overflow-hidden lg:relative bg-transparent py-12 sm:py-16 lg:py-20">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="max-w-5xl mx-auto">
                            {!this.state.eventLoading &&
                            <h1 className="mt-1 mb-2 font-extrabold text-white text-3xl md:text-5xl  sm:tracking-tight lg:text-5xl">{ this.state.eventTitle }</h1>}
                            <p className="mt-5 md:text-2xl text-xl text-white">Meet James Cornell, Managing Director of
                                Life
                                Panels</p>
                        </div>
                        {this.state.eventLoading && <LoadingTextAnimation />}
                        <div className="max-w-6xl mx-auto bg-white rounded-tl-lg rounded-tr-lg">
                            <div className="bg-white rounded-lg shadow-xl">
                                <div
                                    className="grid md:grid-cols-12 sm:grid-cols-1 md:gap-y-20 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8">
                                    <div className="md:col-span-3">
                                        <div className="md:ml-8 ml-4 mt-12 mb-8">
                                            <div className="float-left">
                                                <LocationMarkerIcon strokeWidth="1"
                                                    className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div className="text-gray-700 ml-12"><span
                                                className="text-3xl font-semibold  block">FREE Online</span> <span
                                                className="text-2xl  block">Evitat Community</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-5">
                                        <div className="md:mt-12 mb-8 md:ml-8 ml-4">
                                            <div className="float-left">
                                                <CalendarIcon strokeWidth="1" className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                              aria-hidden="true"/>
                                            </div>
                                            <div className="text-gray-700 ml-12">
                                                <span className="text-3xl font-semibold block">{ Moment(this.state.eventStartAt).format('ddd Do MMM YYYY') },</span>
                                                <span className="text-2xl  block">{ Moment(this.state.eventStartAt).format('hh:mm a') } - { Moment(this.state.eventEndAt).format('hh:mm a') } {this.state.eventTimeZone} </span>
                                                <span className="block">
                                                    <a href="#" className="text-green-300 no-underline text-base">Add to calendar</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="md:col-span-4">
                                        <div className="md:mt-16 ml-4 md:ml-8 md:ml-0 mr-4 md:mr-8 mb-8 md:mb-0">
                                            {!this.state.eventButtonLoading &&
                                            <button
                                                onClick={e => this.handleRSVP(e)}
                                                disabled={this.state.isDisabled} title={this.state.hoverMessage}
                                                type="button"
                                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium ${this.state.eventButtonConfig.action === "undoRSVP" ? "text-white bg-green-500" : "text-indigo-500 bg-green-200 hover:bg-green-300"}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300`}
                                            >
                                                {this.checkIconHandler()}
                                                {this.state.eventButtonConfig.text}
                                            </button>}
                                            {this.state.eventButtonLoading &&  <ButtonLoadingAnimation />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.eventButtonConfig.action === "undoRSVP" &&
                            <div className="bg-white mt-10">
                                <div className="ml-5 mr-5 pb-5 border-b border-gray-300">
                                    <div className="text-center px-2">
                                        <h2 className="text-2xl font-semibold text-green-500">Thanks for
                                            registering!</h2>
                                        {/*<h3 className="text-lg text-green-500">Ticket Order #0123456789</h3>*/}
                                    </div>
                                </div>
                                <div className="ml-5 mr-5 pb-5">
                                    <div className="mx-auto max-w-md md:px-4 md:max-w-4xl">
                                        <div className="bg-white py-6 md:px-4 sm:p-6">
                                            <div className="mb-12">
                                                <p className="text-2xl text-gray-900">You're attending </p>
                                                <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900">{ this.state.eventTitle } - free webinar</h1>
                                            </div>
                                            <div
                                                className="grid md:grid-cols-12 sm:grid-cols-1 md:gap-y-20 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8">
                                                <div className="md:col-span-7">
                                                    <p className="text-2xl text-gray-900">Check your inbox! Confirmation
                                                        sent to <span
                                                            className="block text-lg">{this.state.getUser.email}</span>
                                                    </p>
                                                    <p><a href="#" className="text-lg text-green-300">Send to another
                                                        email address</a></p>
                                                </div>
                                                <div className="md:col-span-5">
                                                    <div className="text-gray-700">
                                                        <span className="text-2xl block">Date</span>
                                                        <span className="text-3xl font-semibold block">{ Moment(this.state.eventStartAt).format('ddd Do MMM YYYY') },</span>
                                                        <span
                                                            className="text-2xl  block">{ Moment(this.state.eventStartAt).format('hh:mm a') } - { Moment(this.state.eventEndAt).format('hh:mm a') } {this.state.eventTimeZone}</span>
                                                        <span className="block"><a href="#"
                                                                                   className="text-green-300 no-underline text-base">Add to calendar</a> </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden lg:relative bg-transparent bg-gray-50">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="max-w-6xl mx-auto">
                            <div className="bg-gray-50">
                                <div
                                    className="grid md:grid-cols-12 sm:grid-cols-1 gap-y-20 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8 pb-12 border-b border-black">
                                    <div className="md:col-span-8">
                                        <div>
                                            <h2 className="font-semibold mb-6 text-2xl">About this event</h2>
                                            <p className="text-lg">If you are thinking about installing wall insulation
                                                but
                                                are
                                                not sure where to
                                                start, or simply want to know more about the appropiate building
                                                materials,
                                                then
                                                this session is for you. </p>
                                            <p className="text-lg">Our host Simone Schenkel, a Passive House Expert,
                                                will
                                                talk
                                                with James Cornell,
                                                Managing Director of Life Panels about the basics insulation and wood
                                                fibre
                                                and
                                                you will leave the session with the information and resources you need
                                                to
                                                make
                                                the right insulation decisions for your home, school or business.</p>
                                        </div>
                                        <div className="mt-12">
                                            <h2 className="font-semibold mb-6 text-2xl">Your privacy & disclaimer</h2>
                                            <p className="text-lg">Evitat uses third party services for the necessary
                                                performance of its functions.
                                                By registering for an event through this website, you agree to the
                                                Library’s
                                                Terms and Conditions and Privacy Policy. The Library encourages you to
                                                read
                                                any
                                                terms or privacy policies of all third party service providers to
                                                understand
                                                how
                                                they may manage your information.</p>
                                        </div>
                                        <div className="flex">
                                            <ul className="w-full ml-0">
                                                <li className="md:float-left w-auto md:mr-8">
                                                    <a href="#" className="no-underline text-gray-700 text-lg">
                                                        <UploadIcon className="float-left w-6 h-6 mr-1.5 text-gray-700"
                                                                    aria-hidden="true"/>
                                                        <span>Share this event</span>
                                                    </a>
                                                </li>
                                                <li className="md:float-left w-auto">
                                                    {/* <PopupButton id="cjN3ZPe8" className="no-underline text-gray-700  text-lg font-medium">
                                                        <MailIcon className="float-left w-6 h-6 mr-1.5 text-gray-700"
                                                                  aria-hidden="true"/>
                                                        <span>Any questions? Contact us.</span>
                                                    </PopupButton> */}
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="md:col-span-4">
                                        <h2 className="font-semibold mb-6 text-2xl">Panelists</h2>

                                        {panelists.map((item) => (
                                            <p className="text-lg" key={item.name}>{item.name} ({item.designation}) <span
                                                className="block text-base font-semibold">{item.company}</span></p>
                                        ))}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </EventLayout>
        )
    }
}

export default EventPage
